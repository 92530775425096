import React from "react";
import { WearthatLoading } from "../../../public/Theme";

const WearthatLoader = ({ className }) => {
  return (
    <div
      className={
        " min-h-screen  h-screen grid justify-center items-center " + className
      }
    >
      <img src={WearthatLoading} alt="wearthat progress loader" />
    </div>
  );
};

export default WearthatLoader;
