import React from "react";
import { getSidebarMenuList } from "./constants";
import Link from "next/link";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat/TypographyMontserrat";
import { DashboardRightArrowSVG } from "../../Atoms/SVGs";
import { usePathname } from "next/navigation";
import {
  BOTTOM_BORDER_CLASS,
  MENU_LINK_CLASS,
  MENU_LINK_CUSTOM_TEXT_CLASS,
  SELECTED_LINK_STYLE,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_TEXT } from "../../../public/Constants/EnumConstants";
import { resetAllSlices } from "../../../store/combinedResetActions";

const SidebarLink = ({ text, selectedStyle }) => (
  <div
    className={
      MENU_LINK_CLASS +
      selectedStyle +
      (text !== LOGOUT_TEXT ? BOTTOM_BORDER_CLASS : null)
    }
  >
    <TypographyMontserrat
      color={"inherit"}
      fontWeight={selectedStyle ? "bold" : "medium"}
      size={"base"}
      text={text}
      customStyle={MENU_LINK_CUSTOM_TEXT_CLASS + selectedStyle}
    />
    {text !== LOGOUT_TEXT ? <DashboardRightArrowSVG /> : null}
  </div>
);

const DashboardSmallMenu = () => {
  const pathName = usePathname();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const menuItems = getSidebarMenuList(userData);
  return (
    <div>
      {menuItems?.map(({ text, link }, index) => {
        const isSelected =
          link === "/dashboard"
            ? pathName === "/dashboard/" // Exact match for Dashboard
            : pathName?.includes(link);
        const selectedStyle = isSelected ? SELECTED_LINK_STYLE : "";
        return (
          <Link href={link} key={index} prefetch>
            <div
              onClick={() =>
                text === LOGOUT_TEXT ? dispatch(resetAllSlices()) : null
              }
            >
              <SidebarLink text={text} selectedStyle={selectedStyle} />{" "}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default DashboardSmallMenu;
