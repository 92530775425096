import Link from "next/link";
import React from "react";
import DashboardSideBarItem from "./DashboardSidebarItem";
import { usePathname } from "next/navigation";

const SidebarLink = ({ link, text, icon }) => {
  const pathName = usePathname();
  const isSelected = link === "/dashboard"
  ? pathName === "/dashboard/" // Exact match for Dashboard
  : pathName?.includes(link);   // General match for other links

  return (
    <Link href={link} passHref prefetch>
      <DashboardSideBarItem
        Icon={icon}
        text={text}
        selected={isSelected}
      />
    </Link>
  );
};

export default SidebarLink;
