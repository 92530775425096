import { resetUser } from "./userSlice";
import { resetBox } from "./boxOrderSlice";
import { resetPayment } from "./paymentSlice";
import { resetPreference } from "./preferencesSlice";

export const resetAllSlices = () => (dispatch) => {
  dispatch(resetBox());
  dispatch(resetUser());
  dispatch(resetPayment());
  dispatch(resetPreference());
  window.location.href = "/login";
};
